import React from "react"
import styled from "styled-components"

// Components
import Link from "./atoms/Link"

// Styling
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

interface IProps {
  nr?: number
  title: string
  text: string
  link?: {
    label: string
    url: string
  }
}

const PrivacySection: React.FCS<IProps> = ({
  nr,
  title,
  text,
  className,
  link,
}) => (
  <Container className={className}>
    <Title>
      {nr ? `${nr}.` : ""} {title}
    </Title>
    <Text dangerouslySetInnerHTML={{ __html: text }} />
    {link && (
      <Link
        type="external"
        target="_blank"
        to={link.url}
        title={link.label}
        color={colors.purpleLight}
      />
    )}
  </Container>
)

const Container = styled.div`
  z-index: 1;
  position: relative;
  max-width: 800px;
`

const Title = styled.h2`
  ${textStyles.body};
  font-weight: 700;
`

const Text = styled.div`
  ${textStyles.body}

  > p {
    ${textStyles.body}

    > a {
      color: ${colors.purpleLight};
    }
  }

  > ul,
  ol {
    margin-bottom: 18px;
    margin-left: 32px;

    ${mq.from.S`
      margin-bottom: 20px;
      margin-left: 56px;
    `}

    > li {
      margin-bottom: 8px;

      ${mq.from.S`
        margin-bottom: 12px;
      `}
    }
  }
`

export default PrivacySection
