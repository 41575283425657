import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Styling
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import PrivacySection from "../components/PrivacySection"

// Types
import { ITermsPage } from "../prismic-types/terms-page"

interface IProps {
  content: ITermsPage
}

const TermsPage: React.FC<IProps> = ({ content }) => {
  const slug = "terms"
  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={content.meta.modifiedDate}
        datePublished={content.meta.publishedDate}
        slug={slug}
        lang={content.meta.lang}
        breadCrumbItems={[{ name: "Terms and Conditions", url: slug }]}
      />
      <Container>
        <StyledContentWrapper>
          <Title>{content.page.title}</Title>
          <Sections>
            {content.sections.map((section) => (
              <StyledPrivacySection
                key={section.title}
                title={section.title}
                text={section.text}
                link={
                  section.link
                    ? {
                        label: section.title,
                        url: section.link,
                      }
                    : undefined
                }
              />
            ))}
          </Sections>
        </StyledContentWrapper>
      </Container>
    </>
  )
}

const Container = styled.section`
  margin-bottom: 120px;
  padding-top: 100px;

  ${mq.from.S`
    margin-bottom: 240px;
    padding-top: 200px;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  ${mq.from.M`
    max-width: calc(10/12*100%);
  `}

  ${mq.from.L`
    max-width: calc(8/12*100%);
  `}
`

const Title = styled.h1`
  ${textStyles.titleXL};
  z-index: 1;
  position: relative;
  margin-bottom: 40px;

  ${mq.from.S`
    margin-bottom: 64px;
  `}
`

const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledPrivacySection = styled(PrivacySection)`
  margin-bottom: 40px;

  ${mq.from.S`
    margin-bottom: 64px;
  `}

  :last-of-type {
    margin-bottom: 0;
  }
`

export const query = graphql`
  query termsPage($locale: String!) {
    prismicTerms(lang: { eq: $locale }) {
      lang
      first_publication_date
      last_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }

        # Sections
        sections {
          section_title {
            text
          }
          section_text {
            text
          }
          section_link {
            url
          }
        }
      }
    }
  }
`

export default ({ data }: any) => {
  const {
    data: raw,
    lang,
    first_publication_date,
    last_publication_date,
  } = data.prismicTerms

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const page = {
    title: raw.title[0].text,
  }

  const sections = raw.sections.map((section: any) => {
    return {
      title: section.section_title[0].text,
      text: section.section_text[0].text,
      link: section.section_link.url,
    }
  })

  const content = {
    meta,
    page,
    sections,
  }

  return <TermsPage content={content} />
}
